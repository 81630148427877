import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { INSURANCE_PLAN } from 'constants/insurancePlans';
import { useModal } from 'contexts/modalProvider.context';

import BRButton from 'components/BRButton/BRButton';
import BRTag from 'components/BRTag/BRTag';
import InsurancePlanModal from './InsurancePlanModal/InsurancePlanModal';
import InsuranceTermsAndCond from './InsurancePlanModal/components/InsuranceTermsAndCond/InsuranceTermsAndCond';

import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';

import './InsurancePlanCard.less';

const InsurancePlanCard = ({
  intl,
  history,
  getPlans,
  plan = {},
  handleSignupSuccess,
  handleClosePopupModal
}) => {
  const { openModal } = useModal();
  const isSettings = history.location.pathname.includes('settings');
  const {
    rank,
    orderValueFeePercentage,
    isDefault,
    isRecommended,
    canApply,
    nextUpdateAllowedAfter,
    isCurrentBusinessInsurancePlan: isCurrentPlan
  } = plan;

  const formattedPercentage = formatNumbersBasedOnLanguage(
    orderValueFeePercentage * 100
  );
  const handleTermsAndCo = (isChosenPlan = false) => {
    openModal(InsuranceTermsAndCond, {
      isChosenPlan,
      handlePlanSelect
    });
  };
  const planDetails = INSURANCE_PLAN(rank, isDefault, handleTermsAndCo);

  const handlePlanSelect = () => {
    openModal(InsurancePlanModal, {
      plan,
      isSettings,
      getPlans,
      handleSignupSuccess,
      handleClosePopupModal,
      planDetails
    });
  };

  const planDetailsBullets = () =>
    isDefault ? (
      <ul className="br-insurance-modal__default-plan-bullets">
        {planDetails?.map((feature, index) => (
          <li
            key={index}
            className="br-insurance-modal__info-features-default-plan"
          >
            <span className="br-insurance-modal__info-single-feature">
              {feature}
            </span>
          </li>
        ))}
      </ul>
    ) : (
      <ul className="br-insurance-modal__premium-plan-bullets">
        {planDetails?.map((feature, index) => (
          <li
            key={index}
            className="br-insurance-modal__info-features-premium-plan"
          >
            <CircleCheck />

            <span className="br-insurance-modal__info-single-feature">
              {feature}
            </span>
          </li>
        ))}
      </ul>
    );

  const renderApplyButton = () => {
    const buttonType = isDefault && !isSettings ? 'outline' : 'primary';
    const buttonLabel =
      isDefault && !isSettings
        ? intl.formatMessage({
            id: `insurance.plans.common.keep_current_plan`
          })
        : intl.formatMessage({
            id: `insurance.plans.common.apply`
          });

    const applyButton = (
      <BRButton
        className="br-insurance-card__premium-plan-btn"
        type={buttonType}
        disabled={!canApply}
        label={buttonLabel}
        onClick={() =>
          isDefault ? handlePlanSelect() : handleTermsAndCo(true)
        }
      />
    );

    if (canApply) {
      return applyButton;
    }

    if (nextUpdateAllowedAfter) {
      return (
        <Tooltip
          overlayClassName="br-insurance-card__btn-tooltip"
          placement={'bottom'}
          title={intl.formatMessage(
            {
              id: `insurance.settings.downgrade_warning`
            },
            {
              date: dayjs(nextUpdateAllowedAfter).format('MMMM DD, YYYY')
            }
          )}
        >
          <div>{applyButton}</div>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className="br-insurance-card__container">
      <div className="br-insurance-card__header">
        <span className="br-insurance-card__header-title">
          {intl.formatMessage({
            id: `insurance.plans.rank_${rank}.title`
          })}
        </span>
        {isRecommended && (
          <span className="br-insurance-card__header-tag ">
            <BRTag
              color="green"
              children={intl.formatMessage({
                id: `insurance.plans.common.recommended`
              })}
            />
          </span>
        )}
      </div>

      <div className="br-insurance-card__content">
        <div className="br-insurance-card__offer">
          <span className="br-insurance-card__offer-title">
            {intl.formatMessage({
              id: 'insurance.plans.common.you_will_pay'
            })}
          </span>
          <div>
            <span className="br-insurance-card__offer-percentage">
              {intl.formatMessage(
                {
                  id: `insurance.plans.rank_${rank}.percentage`
                },
                {
                  value: formattedPercentage
                }
              )}
            </span>
            <span className='className="br-insurance-card__offer-subtitle"'>
              {intl.formatMessage({
                id: 'insurance.plans.common.orders_value'
              })}
            </span>
          </div>
        </div>

        <div className="br-insurance-card__info">
          <span className="br-insurance-card__info-title">
            {intl.formatMessage({
              id: `insurance.plans.common.package_advantages`
            })}
          </span>
          {planDetailsBullets()}
        </div>

        {isCurrentPlan ? (
          <span className="br-insurance-card__current-plan-btn">
            {intl.formatMessage({
              id: `insurance.settings.current_plan`
            })}
          </span>
        ) : (
          renderApplyButton()
        )}
      </div>
    </div>
  );
};

export default withRouter(injectIntl(InsurancePlanCard));
