export const sendSegment = async (eventName, params, State = 'Success') => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))?.user;
  const dataToSend = {
    email: userInfo?.emails[0]?.address,
    Source: 'Business',
    'Business ID': userInfo?.businessAdminInfo?.businessId || null,
    'Business Name': userInfo?.businessAdminInfo?.businessName,
    'Dashboard Source': 'Bosta_DB',
    State,
    'Last Open Date': Date.now(),
    ...params
  };
  window.analytics && (await window.analytics.track(eventName, dataToSend));
};
