export const identifyFreshSalesUser = (email) => {
  if (!window.FM) {
    return;
  }
  window.FM.associateVisitor(email);
};

export const trackFreshSalesCustomEvents = (eventName, properties) => {
  if (!window.FM) {
    return;
  }
  window.FM.trackCustomEvent(eventName, properties);
};
