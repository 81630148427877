import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Table, Tabs, Input, Button, Tooltip, DatePicker } from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import dayjs from 'dayjs';

import { mediaHook } from 'customHooks';
import diff from 'diffObj';
import { cleanEmptyString } from 'utils/helpers';
import { ModalContext } from 'contexts/modalProvider.context';
import { arrayMove } from 'utils';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helpers';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import TableFilters from './Components/TableFilters';
import MobileCards from './Components/MobileCards/MobileCards';
import BRButton from 'components/BRButton/BRButton';
import CreateProductModal from 'components/Orders/components/CreateEditOrder/components/OrderDetails/CreateProductModal/CreateProductModal';
import DraggableRow from './Components/DraggableRow/DraggableRow';
import BRSelect from 'components/BRSelect/BRSelect';

import { ReactComponent as Search } from 'assets/bosta-icons/Search.svg';
import { ReactComponent as Filters } from 'assets/bosta-icons/Filters.svg';
import { ReactComponent as Download } from 'assets/bosta-icons/Download.svg';
import { ReactComponent as ClearCircle } from 'assets/bosta-icons/Circle-clear.svg';
import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Right } from 'assets/bosta-icons/Right.svg';
import { ReactComponent as Left } from 'assets/bosta-icons/Left.svg';
import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';

import './BRTable.less';

class BRTable extends React.PureComponent {
  state = {
    listingData: [],
    pageSize: this.props.pageLimit || TABLE_PAGE_SIZE_OPTIONS[0],
    isLoading: this.props.isLoading || false,
    count: 0,
    pageNumber: this.props.initialPageNumber || 1,
    selectedRowKeys: [],
    fixedTop: false,
    activeTabKey: !!this.props.quickFilters?.length
      ? this.props.initialQuickFilter
        ? this.props.initialQuickFilter
        : 0
      : null,
    filterValues: this.props.initialFilterValue || {},
    isUserUsedSearch: false,
    prevPayLoad: {},
    searchInputState: {
      isSearchInputActive: false,
      isSearchInputActiveWithoutShadow: false,
      showClearCircle: false
    },
    isFiltersClicked: this.props.initialFilterValue || false,
    filterLabels: this.props.initialFilterLabels || [],
    selectedCards: [],
    isSelectButtonClicked: false,
    addProductText: (
      <>
        <span>
          {this.props.intl.formatMessage({
            id: `new_order.order_details.order_types.view_all_modal.add`
          })}
        </span>
        <span>
          {this.props.intl.formatMessage({
            id: 'new_order.order_details.order_types.view_all_modal.add_product_button_without_value'
          })}
        </span>
      </>
    )
  };

  static contextType = ModalContext;

  filtersRef = React.createRef('');

  getData = async ({
    pageNumber = 1,
    pageLimit,
    isInitialLoad,
    refresh = false,
    ...props
  }) => {
    const {
      listFunction,
      handleSelectedRows,
      sendFilterLabels,
      allowUserUsedSearchOnErrors
    } = this.props;
    const { pageSize, filterValues, prevPayLoad, filterLabels, searchBy } =
      this.state;
    const payload = {
      page: pageNumber,
      limit: pageLimit || pageSize,
      searchBy,
      ...filterValues,
      ...props,
      ...(sendFilterLabels && { filterLabels })
    };

    const isNewPayload = diff(
      cleanEmptyString(payload),
      cleanEmptyString(prevPayLoad)
    );

    if (!Object.keys(isNewPayload).length && !refresh) {
      return;
    }
    if (handleSelectedRows) {
      handleSelectedRows([]);
    }
    try {
      this.setState({ isLoading: true });
      const fetchData = listFunction;
      const data = await fetchData(payload);
      if (data) {
        const { list, total } = data;
        this.setState({
          listingData: list,
          count: total,
          selectedRowKeys:
            isInitialLoad && this.props.selectAllInitially
              ? list.map((_, index) => index)
              : this.props.selectedRows || [],
          pageNumber,
          isLoading: false,
          isUserUsedSearch: !!filterValues?.searchInputText,
          prevPayLoad: payload
        });
      } else {
        this.setState({
          listingData: [],
          count: 0,
          pageNumber,
          isLoading: false,
          prevPayLoad: payload,
          isUserUsedSearch: allowUserUsedSearchOnErrors
            ? !!filterValues?.searchInputText
            : false
        });
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
    this.setState({ isLoading: false });
  };

  refresh({ pageNumber: pageNumberProp, isPageEmpty = false } = {}) {
    const { pageNumber } = this.state;
    this.getData({
      pageNumber: pageNumberProp
        ? pageNumberProp
        : isPageEmpty && pageNumber > 1
        ? pageNumber - 1
        : pageNumber || 1,
      isInitialLoad: true,
      refresh: true
    });
  }

  componentDidMount() {
    const { listFunction, listingData, selectedRows = [] } = this.props;
    const { pageNumber } = this.state;
    if (listFunction) {
      this.getData({ pageNumber, isInitialLoad: true });
    } else if (listingData) {
      this.setState({
        listingData,
        selectedRowKeys: selectedRows
      });
    }
    if (this.props.shareMethods) {
      this.props.shareMethods(this.refresh.bind(this));
    }
  }

  componentDidUpdate(prevProps, _) {
    const {
      currentQuickFilterIndex,
      initialFilterLabels,
      initialFilterValue,
      isLoading,
      resetSelection,
      handleSelectedRows = () => {},
      listingData
    } = this.props;
    if (prevProps.currentQuickFilterIndex !== currentQuickFilterIndex) {
      this.onChangeQuickFilterTab(currentQuickFilterIndex);
    }
    if (prevProps.initialFilterLabels !== initialFilterLabels) {
      this.setState({ filterLabels: initialFilterLabels });
    }
    if (prevProps.initialFilterValue !== initialFilterValue) {
      this.setState({ filterValues: initialFilterValue });
    }
    if (prevProps.isLoading !== isLoading) {
      this.setState({ isLoading: isLoading });
    }
    if (prevProps.resetSelection !== resetSelection) {
      this.setState({
        selectedRowKeys: [],
        selectedCards: [],
        isSelectButtonClicked: false
      });
      handleSelectedRows([]);
    }
    if (prevProps.listingData !== listingData) {
      this.setState({ listingData });
    }
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    if (pagination.pageSize) {
      this.setState({ pageSize: pagination.pageSize });
    }

    if (this.props.listFunction) {
      const { column, field, order } = sorter;
      this.getData({
        pageNumber: pagination.current,
        pageLimit: pagination.pageSize,
        ...(column?.sortKey
          ? {
              sortedBy: `${order === 'descend' ? '-' : ''}${column.sortKey}`
            }
          : {
              sortField: field,
              sortOrder: order
            })
      });
    } else {
      this.setState({
        pageNumber: pagination.current
      });
    }
  };

  cell = ({ children }) => {
    return <td className="br-table-row__cell body">{children[1]}</td>;
  };

  headerRow = ({ children }) => {
    const { selectedRowKeys } = this.state;
    const { title, isScrolling } = this.props;
    return selectedRowKeys.length && isScrolling ? (
      <div className="display-flex align-center">
        <tr> {children[0]}</tr>
        <tr> {title}</tr>
      </div>
    ) : (
      <tr>{children.map((child) => child)}</tr>
    );
  };

  onChangeQuickFilterTab = (index) => {
    const { keepDataBetweenTabs = false, quickFilters } = this.props;
    const { filterValues } = this.state;
    this.props.handleQuickFilterChange &&
      this.props.handleQuickFilterChange(index, quickFilters?.[index]?.value);
    const defaultState = {
      activeTabKey: index,
      selectedRowKeys: [],
      selectedCards: [],
      isSelectButtonClicked: false
    };
    this.setState(
      keepDataBetweenTabs
        ? {
            filterValues: {
              ...filterValues,
              quickFiltersValue: quickFilters[index]?.value
            },
            ...defaultState
          }
        : {
            filterValues: { quickFiltersValue: quickFilters[index]?.value },
            selectedCards: [],
            isSelectButtonClicked: false,
            isFiltersClicked: false,
            filterLabels: [],
            ...defaultState
          },
      () =>
        this.getData({
          pageNumber: 1,
          isInitialLoad: true
        })
    );
  };

  handleClearSearch = () => {
    const { intl, setSearchValue, onSearchClear = () => {} } = this.props;
    onSearchClear();

    this.setState(
      {
        activeTabKey: 0,
        filterValues: {},
        filterLabels: {},
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        },
        isFiltersClicked: false
      },
      () => {
        this.getData({
          pageNumber: 1
        });
      }
    );
    this.resetAddProductContent();
    setSearchValue && setSearchValue('');
  };

  scrollToFilters = () => {
    this.filtersRef.current.scrollIntoView();
  };

  handleExportFile = async () => {
    const { selectedRowKeys, filterValues } = this.state;
    const { exportListFileFunction } = this.props;
    const payload = {
      ...(selectedRowKeys.length && { selectedRowKeys })
    };
    this.setState({ isLoading: true });
    try {
      await exportListFileFunction(payload, filterValues);
    } catch (error) {
      notify({ msg: error.message, error });
    }
    this.setState({ isLoading: false });
  };

  onSearch = (searchInputText) => {
    const {
      quickFilters = false,
      keepDataBetweenTabs = false,
      setSearchValue
    } = this.props;
    const { filterValues, searchInputState, activeTabKey } = this.state;
    this.setState(
      {
        searchInputState: {
          ...searchInputState,
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: true
        },
        activeTabKey: keepDataBetweenTabs
          ? activeTabKey
          : quickFilters[0]?.value,
        filterValues: {
          ...filterValues,
          searchInputText,
          quickFiltersValue: null
        }
      },
      () => {
        this.getData({ pageNumber: 1 });
      }
    );
    setSearchValue && setSearchValue(searchInputText);
  };

  handleSearchInputActiveState = ({ target: { value } }) => {
    const { filterValues } = this.state;
    const { intl } = this.props;
    this.setState({
      filterValues: { ...filterValues, searchInputText: value },
      searchInputState: {
        isSearchInputActive: !!value,
        isSearchInputActiveWithoutShadow: false,
        showClearCircle: !!value
      }
    });
    value
      ? this.setState({
          addProductText: (
            <>
              <span>
                {intl.formatMessage({
                  id: `new_order.order_details.order_types.view_all_modal.add`
                })}
              </span>
              <>
                <span className="br-order-details__header-product-name">
                  {intl.formatMessage(
                    {
                      id: `new_order.order_details.order_types.view_all_modal.add_product_button_value`
                    },
                    { value: value }
                  )}
                </span>
                <span>"</span>
              </>
              <span>
                {intl.formatMessage({
                  id: `new_order.order_details.order_types.view_all_modal.add_product_button`
                })}
              </span>
            </>
          )
        })
      : this.resetAddProductContent();
  };

  handleOnSearchByChange = (searchBy) => {
    const { handleSearchByChange = () => {} } = this.props;
    handleSearchByChange(searchBy);
    this.setState({ searchBy });
  };

  inputSearchComponent = () => {
    const { searchPlaceholder = '', searchByOptions, intl } = this.props;
    const { isLoading, searchInputState, filterValues, isUserUsedSearch } =
      this.state;
    return (
      <div className="br-table__header__input-search-container">
        {searchByOptions && (
          <BRSelect
            className="br-table__header__input-search-by"
            showSearch={false}
            selectValueKey="value"
            selectLabelKey="label"
            options={searchByOptions}
            dropdownTitle={intl.formatMessage({ id: 'common.search_by' })}
            initialValue={searchByOptions[0].value}
            handleChangeValue={(_, value) => this.handleOnSearchByChange(value)}
            dropdownMatchSelectWidth={160}
          />
        )}
        <Input.Search
          className={classnames('br-table__header__input-search', {
            'br-table__header__input-search--active':
              searchInputState.isSearchInputActive,
            'br-table__header__input-search--active__no-shadow':
              searchInputState.isSearchInputActiveWithoutShadow,
            'br-table__header__input-search-by-active': searchByOptions
          })}
          placeholder={searchPlaceholder}
          value={filterValues?.searchInputText}
          suffix={
            <Icon
              onClick={this.handleClearIcon}
              className="ant-icon-md"
              component={
                searchInputState.showClearCircle
                  ? ClearCircle
                  : !filterValues?.searchInputText?.length &&
                    !searchInputState.isSearchInputActiveWithoutShadow &&
                    Search
              }
            />
          }
          disabled={isLoading}
          onSearch={this.onSearch}
          onChange={this.handleSearchInputActiveState}
          onFocus={() => {
            this.setState({
              searchInputState: {
                ...searchInputState,
                isSearchInputActiveWithoutShadow: false,
                isSearchInputActive:
                  filterValues?.searchInputText?.length && !isUserUsedSearch
              }
            });
          }}
          onBlur={() =>
            this.setState({
              searchInputState: {
                ...searchInputState,
                isSearchInputActive: false,
                isSearchInputActiveWithoutShadow:
                  filterValues?.searchInputText?.length
              }
            })
          }
        />
      </div>
    );
  };

  handleClearIcon = (e) => {
    const { isUserUsedSearch, filterValues } = this.state;
    const { intl, setSearchValue } = this.props;
    e.stopPropagation();
    this.setState(
      {
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        },
        filterValues: {
          ...filterValues,
          searchInputText: null
        }
      },
      () => {
        isUserUsedSearch && this.getData({ pageNumber: 1 });
      }
    );
    this.resetAddProductContent();
    setSearchValue && setSearchValue('');
  };

  handleFilters = () => {
    const { isFiltersClicked } = this.state;
    const { keepDataBetweenTabs } = this.props;
    this.setState(
      {
        isFiltersClicked: !isFiltersClicked,
        filterValues: {},
        selectedCards: [],
        filterLabels: [],
        activeTabKey: keepDataBetweenTabs ? this.state.activeTabKey : 0,
        isSelectButtonClicked: false
      },
      () => this.getData({ pageNumber: 1 })
    );
  };
  onChangeDatePicker = async (dateObjects, dateValues) => {
    const { filterValues } = this.state;
    this.setState(
      {
        pageId: 1,
        filterValues: {
          scheduledAtStart:
            dateValues[0] &&
            dayjs(dateObjects[0])
              .utc()
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .format(),

          scheduledAtEnd:
            dateValues[1] &&
            dayjs(dateObjects[1])
              .utc()
              .set('hour', 23)
              .set('minute', 59)
              .set('second', 59)
              .format()
        },
        pickerOpen: false,
        isSearchResult: true
      },
      () => this.getData({ pageId: 1, filterValues })
    );
  };

  updateFilterLabels = (filtersLabels) => {
    this.setState(
      {
        filterLabels: filtersLabels,
        isInitialLoad: true
      },
      () => {
        this.getData({ pageNumber: 1 });
      }
    );
  };

  handleClearFilter = ({ key, value }) => {
    const { filterLabels, filterValues } = this.state;
    let getKey = '';
    let newFilter = { ...filterValues };
    Object.keys(filterValues).filter((item) => {
      value.map((val) => {
        if (`${val?.dateKey}Start` === item || `${val?.key}Start` === item) {
          delete newFilter[item];
        }
        if (`${val?.dateKey}End` === item || `${val?.key}End` === item) {
          delete newFilter[item];
        }
        if (val.key === item) {
          delete newFilter[item];
        }
      });
    });

    delete newFilter[getKey];
    const newFilterLables = {
      ...filterLabels
    };
    delete newFilterLables[key];
    this.setState(
      {
        filterLabels: newFilterLables,
        filterValues: newFilter
      },
      () => this.getData({ pageNumber: 1 })
    );
    if (!Object.keys(newFilterLables).length) {
      this.setState({
        activeTabKey: 0,
        isFiltersClicked: false,
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        }
      });
    }
  };

  handleFiltersTags = (value) => {
    const limitedTags = [];
    const extraTags = [];
    value?.map(({ label, dateLabel }, index) => {
      if (index < 4) {
        if (dateLabel) {
          limitedTags.push(`${label} ${dateLabel}`);
        } else {
          if (label?.props) {
            limitedTags.push(
              label?.props?.children[0]?.props?.children ||
                label?.props?.children?.props?.children
            );
          } else {
            limitedTags.push(label);
          }
        }
      } else {
        extraTags.push(label);
      }
    });
    return (
      <>
        {limitedTags.map(
          (tag, index) =>
            `${tag}${
              index === limitedTags.length - 2
                ? ', or '
                : index !== limitedTags.length - 1
                ? ', '
                : ''
            }`
        )}
        {value?.length > 4 && (
          <Tooltip
            key={value}
            overlayClassName="br-table-filters__extra-tags"
            title={extraTags.map((item, index) => (
              <div>
                {item}
                {index === extraTags.length - 2
                  ? ', or'
                  : index !== extraTags.length - 1
                  ? ','
                  : ''}
              </div>
            ))}
          >
            <span className="br-table-filters__extra-tags__label subheading">
              +{value.length - 4}
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  cutsomizedPaginationIcons = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className="br-table-pagination-prev-icon">
          <Left />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className="br-table-pagination-next-icon">
          <Right />
        </a>
      );
    }
    return originalElement;
  };

  resetAddProductContent = () => {
    this.setState({
      addProductText: (
        <>
          <span>
            {this.props.intl.formatMessage({
              id: `new_order.order_details.order_types.view_all_modal.add`
            })}
          </span>
          <span>
            {this.props.intl.formatMessage({
              id: 'new_order.order_details.order_types.view_all_modal.add_product_button_without_value'
            })}
          </span>
        </>
      )
    });
  };

  renderEmptyState = () => {
    const { emptyState, specificIndexEmptyState, intl } = this.props;
    const { activeTabKey, emptySearchContent, isFiltersClicked } = this.state;
    return !isNaN(specificIndexEmptyState) ? (
      Number(activeTabKey) === specificIndexEmptyState ? (
        emptyState(this.handleClearSearch)
      ) : (
        <BREmptyState
          className="br-table-component__empty-state"
          title={emptySearchContent?.title}
          subTitle={emptySearchContent?.subtitle}
          actions={
            <Button type="primary" onClick={this.handleClearSearch}>
              {intl.formatMessage({
                id: `common.${
                  isFiltersClicked ? 'clear_filters' : 'clear_search'
                }`
              })}
            </Button>
          }
          search={true}
        />
      )
    ) : (
      emptyState(this.handleClearSearch)
    );
  };

  moveRow = (dragIndex, hoverIndex) => {
    if (dragIndex !== hoverIndex) {
      const { onDragFinish } = this.props;
      const newListingData = arrayMove(
        [...this.state.listingData],
        dragIndex,
        hoverIndex
      );
      this.setState({ listingData: newListingData });
      if (onDragFinish) {
        onDragFinish({ newData: newListingData, newIndex: hoverIndex });
      }
    }
  };

  render() {
    const {
      columns,
      className,
      pageSizeOptions = TABLE_PAGE_SIZE_OPTIONS,
      intl,
      quickFilters,
      title,
      emptyState,
      mobileScreenSizes,
      emptySearchContent,
      isScrolling,
      tableFilters,
      showSearch,
      MobileCardsRows,
      allowSelection = false,
      selectAllInitially,
      noFilterAppliedEmptyState,
      handleOnClickCard = () => {},
      handleSelectedRows,
      onRowClick,
      handlePrintSelectedRows,
      handleDeleteSelectedRows,
      mobileCardActions,
      summary = false,
      tableActions,
      scrollTableAt = 1040,
      rowClassName,
      getCheckboxProps,
      showFilter = true,
      exportListFileFunction,
      mobileCardsAction,
      addProductButton,
      hidePagination = false,
      isDraggableRows,
      savedTableData,
      showDateFilter,
      showExportButton,
      rowsRef,
      ...props
    } = this.props;
    const {
      listingData,
      isLoading,
      pageSize,
      count,
      pageNumber,
      activeTabKey,
      isFiltersClicked,
      selectedRowKeys,
      filterValues,
      filterLabels,
      selectedCards,
      isSelectButtonClicked,
      addProductText
    } = this.state;

    const { openModal } = this.context;

    const rowSelection = allowSelection
      ? {
          selectedRowKeys,
          columnWidth: '20',
          onChange: (selectedRowKeys) => {
            this.setState({ selectedRowKeys });
            const { handleSelectedRows } = this.props;
            if (handleSelectedRows) {
              handleSelectedRows(selectedRowKeys);
            }
          },
          getCheckboxProps
        }
      : null;

    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <div
            key="_id"
            className={classnames('br-table-component', className, {
              'br-table-component__empty-state':
                !listingData.length && !isLoading,
              'br-table__small-mobile': mobileScreenSizes?.isSmallMobileScreen,
              'br-table__medium-mobile':
                mobileScreenSizes?.isMediumMobileScreen,
              'br-table__fixed-header': isScrolling && selectedRowKeys.length,
              'br-table-component__no-selection': !allowSelection,
              'br-table-component__no-header':
                !quickFilters?.length && !showSearch && !showFilter && !title
            })}
          >
            {(!!quickFilters?.length || showSearch || showFilter || title) && (
              <div className="br-table__quick-filter-tabs">
                {!!quickFilters?.length && (
                  <Tabs
                    activeKey={`${activeTabKey}`}
                    tabBarGutter={0}
                    onChange={this.onChangeQuickFilterTab}
                  >
                    {quickFilters?.map(
                      ({ label, isUserAuthorized = true }, index) =>
                        isUserAuthorized && (
                          <Tabs.TabPane tab={label} key={index} />
                        )
                    )}
                  </Tabs>
                )}
                <div className="br-table__header" ref={this.filtersRef}>
                  <div className="br-table__title heading">{title}</div>
                  {(tableActions ||
                    showFilter ||
                    showSearch ||
                    tableFilters ||
                    showDateFilter ||
                    showExportButton) && (
                    <div className="br-table__header__actions">
                      {showSearch &&
                        !mobileScreenSizes?.isSmallMobileScreen &&
                        !isFiltersClicked &&
                        this.inputSearchComponent()}
                      {(!showSearch || isFiltersClicked) &&
                        tableFilters &&
                        !mobileScreenSizes?.isSmallMobileScreen && (
                          <TableFilters
                            tableFiltersItems={tableFilters}
                            showSearch={showSearch}
                            updateFilterLabels={this.updateFilterLabels}
                            mobileScreenSizes={mobileScreenSizes}
                            filterData={filterValues}
                            setFilterData={(filterValues) =>
                              this.setState({ filterValues })
                            }
                            appliedFilterLabels={filterLabels}
                            setAppliedFilterLabels={(labels) =>
                              this.setState({ filterLabels: labels })
                            }
                            isLoading={isLoading}
                          />
                        )}
                      {showFilter && (
                        <BRButton
                          prefixIcon={
                            isFiltersClicked ? <Close /> : <Filters />
                          }
                          disabled={isLoading}
                          onClick={this.handleFilters}
                          className={classnames('button-md', {
                            'br-table__header__actions__active-filters':
                              isFiltersClicked
                          })}
                          type="basic"
                          label={
                            !mobileScreenSizes?.isMediumMobileScreen &&
                            !isFiltersClicked &&
                            intl.formatMessage({ id: 'common.filters' })
                          }
                        />
                      )}
                      {!mobileScreenSizes?.isMediumMobileScreen &&
                        showDateFilter && (
                          <DatePicker.RangePicker
                            onChange={this.onChangeDatePicker}
                            format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                          />
                        )}
                      {showSearch && exportListFileFunction && (
                        <BRButton
                          prefixIcon={<Download />}
                          disabled={isLoading}
                          onClick={this.handleExportFile}
                          className="button-md"
                          type="basic"
                          label={
                            !mobileScreenSizes?.isMediumMobileScreen &&
                            !isFiltersClicked &&
                            intl.formatMessage({ id: 'common.export' })
                          }
                        />
                      )}
                      {showExportButton && exportListFileFunction && (
                        <BRButton
                          prefixIcon={<Download />}
                          disabled={isLoading}
                          onClick={this.handleExportFile}
                          className="button-md"
                          type="basic"
                          label={
                            !mobileScreenSizes?.isMediumMobileScreen &&
                            !isFiltersClicked &&
                            intl.formatMessage({ id: 'common.export' })
                          }
                        />
                      )}

                      {tableActions && tableActions()}
                    </div>
                  )}
                </div>
                <div className="br-table__date-filter">
                  {mobileScreenSizes?.isMediumMobileScreen &&
                    showDateFilter && (
                      <DatePicker.RangePicker
                        onChange={this.onChangeDatePicker}
                        format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                      />
                    )}
                </div>
                {showSearch &&
                  mobileScreenSizes?.isSmallMobileScreen &&
                  !isFiltersClicked &&
                  this.inputSearchComponent()}
                {(!showSearch || isFiltersClicked) &&
                  tableFilters &&
                  mobileScreenSizes?.isSmallMobileScreen && (
                    <TableFilters
                      tableFiltersItems={tableFilters}
                      updateFilterLabels={this.updateFilterLabels}
                      mobileScreenSizes={mobileScreenSizes}
                      filterData={filterValues}
                      setFilterData={(filterValues) =>
                        this.setState({ filterValues })
                      }
                      appliedFilterLabels={filterLabels}
                      setAppliedFilterLabels={(labels) =>
                        this.setState({ filterLabels: labels })
                      }
                      isLoading={isLoading}
                    />
                  )}
              </div>
            )}
            {!!filterLabels && !!Object.keys(filterLabels)?.length && (
              <div className="br-table-filters__all-tags">
                {Object.entries(filterLabels).map(([key, value]) => (
                  <div className="display-flex" key={key}>
                    <div className="br-table-filters__tags">
                      <span className="br-table-filters__tags--title caption-lg">
                        {key}
                      </span>
                      <span className="br-table-filters__tags--content caption-lg">
                        {this.handleFiltersTags(value)}
                      </span>
                    </div>
                    <span
                      className="br-table-filters__tags--close-icon"
                      onClick={() => this.handleClearFilter({ key, value })}
                    >
                      <Icon className="ant-icon-xs" component={Close} />
                    </span>
                  </div>
                ))}
              </div>
            )}
            {!!addProductButton && (
              <BRButton
                type={'link-color'}
                prefixIcon={
                  <Icon component={PlusIcon} className="ant-icon-md" />
                }
                label={addProductText}
                onClick={() =>
                  openModal(CreateProductModal, {
                    initialProductName: filterValues?.searchInputText
                  })
                }
              />
            )}
            {!listingData.length && !isLoading ? (
              noFilterAppliedEmptyState &&
              !savedTableData?.savedQuickFilter &&
              !Object.values({
                ...filterValues
              }).filter((n) => n).length ? (
                noFilterAppliedEmptyState()
              ) : emptyState ? (
                this.renderEmptyState()
              ) : (
                <BREmptyState
                  className="br-table-component__empty-state"
                  title={emptySearchContent?.title}
                  subTitle={emptySearchContent?.subtitle}
                  actions={
                    <Button
                      type={emptySearchContent?.actionType || 'primary'}
                      onClick={this.handleClearSearch}
                    >
                      {emptySearchContent?.actionText ||
                        intl.formatMessage({
                          id: `common.${
                            isFiltersClicked ? 'clear_filters' : 'clear_search'
                          }`
                        })}
                    </Button>
                  }
                  search={true}
                />
              )
            ) : mobileScreenSizes.isSmallMobileScreen && MobileCardsRows ? (
              <MobileCards
                dataSource={isLoading ? [] : listingData}
                columns={MobileCardsRows}
                isLoading={isLoading}
                allowSelection={allowSelection}
                isScrolling={isScrolling}
                handleOnClickCard={handleOnClickCard}
                mobileScreenSizes={mobileScreenSizes}
                handleClearSearch={this.handleClearSearch}
                scrollToFilters={this.scrollToFilters}
                selectedCards={selectedCards}
                setSelectedCards={(selectedCards) =>
                  this.setState({ selectedCards })
                }
                isSelectButtonClicked={isSelectButtonClicked}
                setIsSelectButtonClicked={(isSelectButtonClicked) =>
                  this.setState({ isSelectButtonClicked })
                }
                filterLength={
                  filterLabels ? Object.keys(filterLabels)?.length : 0
                }
                handleSelectedCards={
                  handleSelectedRows ? handleSelectedRows : () => {}
                }
                handlePrintSelectedRows={handlePrintSelectedRows}
                handleDeleteSelectedRows={handleDeleteSelectedRows}
                pagination={
                  !hidePagination && {
                    onChange: (pageNumber, pageSize) => {
                      this.setState({ pageNumber, pageSize });
                      this.getData({
                        pageNumber,
                        pageLimit: pageSize
                      });
                    },
                    total: count,
                    current: pageNumber,
                    pageSize: pageSize,
                    locale: {
                      items_per_page: intl.formatMessage({
                        id: 'common.per_page'
                      })
                    },
                    showSizeChanger: true,
                    pageSizeOptions: pageSizeOptions
                  }
                }
                mobileCardsAction={mobileCardsAction}
                moveRow={this.moveRow}
                isDraggableRows={isDraggableRows}
              />
            ) : (
              <Table
                className="br-table"
                rowKey="_id"
                columns={columns}
                dataSource={isLoading ? [] : listingData}
                key={isLoading}
                loading={{
                  spinning: isLoading,
                  indicator: (
                    <LoadingWrapper
                      loading={isLoading}
                      indicator={
                        <div>
                          <LoadingOutlined spin />
                        </div>
                      }
                    />
                  )
                }}
                rowSelection={rowSelection}
                rowClassName={rowClassName}
                components={{
                  body: {
                    row: isDraggableRows
                      ? DraggableRow
                      : ({ index, ...props }) => {
                          return (
                            <tr
                              ref={(el) => {
                                if (!rowsRef) {
                                  return;
                                }
                                if (!rowsRef.current) {
                                  rowsRef.current = [];
                                }
                                rowsRef.current[index] = el;
                              }}
                              {...props}
                            />
                          );
                        }
                  },
                  header: {
                    row: this.headerRow
                  }
                }}
                scroll={{ x: scrollTableAt }}
                onChange={this.handleTableChange}
                pagination={
                  !hidePagination && {
                    position: ['bottomCenter'],
                    total: count,
                    current: pageNumber,
                    pageSize: pageSize,
                    locale: {
                      items_per_page: intl.formatMessage({
                        id: 'common.per_page'
                      })
                    },
                    showSizeChanger: !!pageSizeOptions?.length,
                    pageSizeOptions: pageSizeOptions,
                    itemRender: this.cutsomizedPaginationIcons
                  }
                }
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      const selection = window.getSelection();
                      if (selection.toString().length !== 0) {
                        // selection
                        event.stopPropagation();
                      } else if (event.detail === 2) {
                        // double click
                        event.stopPropagation();
                      } else {
                        // normal single click
                        onRowClick && onRowClick(record);
                      }
                    },
                    moveRow: this.moveRow,
                    index
                  };
                }}
                showSorterTooltip={false}
                sticky
                summary={() =>
                  summary && (
                    <Table.Summary fixed={summary.fixed}>
                      <Table.Summary.Row>
                        {summary.cells.map(
                          ({ content, index, colSpan }, idx) => (
                            <Table.Summary.Cell
                              index={index || idx}
                              colSpan={colSpan || 2}
                            >
                              {content}
                            </Table.Summary.Cell>
                          )
                        )}
                      </Table.Summary.Row>
                    </Table.Summary>
                  )
                }
                {...props}
              />
            )}
          </div>
        </DndProvider>
      </>
    );
  }
}

export default injectIntl(withRouter(mediaHook(BRTable)));
