import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as VisaIcon } from 'assets/bosta-icons/visa-icon.svg';
import { ReactComponent as MasterCardIcon } from 'assets/bosta-icons/mastercard-icon.svg';
import { ReactComponent as MaestroIcon } from 'assets/bosta-icons/maestro-icon.svg';
import { ReactComponent as FlashIcon } from 'assets/bosta-icons/flash-logo.svg';
import AmericanExpressIcon from 'assets/bosta-icons/americanexpress-image.png';
import ApplePayIcon from 'assets/bosta-icons/apple-pay-image.png';
import MadaIcon from 'assets/bosta-icons/mada-image.png';
import ValuIcon from 'assets/bosta-icons/valu.svg';
import ValuAstric from 'assets/bosta-icons/valu_astric.svg';

export const SLLR_PAY_METHODS = {
  mastercard: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.master_card'
    }),
    logo: <MasterCardIcon />
  },
  valu: {
    name: intl.formatMessage({
      id: `orders.shipment_details.shipment_details_summary.table_cells_labels.paid_by_valu`
    }),
    logo: <img src={ValuIcon} alt="valU-logo" />,
    logo_astric: <img src={ValuAstric} alt="valU-logo" />,
    string: 'valu',
    org_name: 'ValU'
  },
  visa: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.visa'
    }),
    logo: <VisaIcon />
  },
  mada: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.mada_card'
    }),
    logo: <img src={MadaIcon} alt="" />
  },
  maestro: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.maestro'
    }),
    logo: <MaestroIcon />
  },
  americanexpress: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.american_express'
    }),
    logo: <img src={AmericanExpressIcon} alt="" />
  },
  applepay: {
    name: fmt({
      id: 'products.sllr_store.sllr_pay.saudi_services.apple_pay'
    }),
    logo: <img src={ApplePayIcon} alt="" />
  },
  flash: {
    name: fmt({ id: 'products.sllr_store.sllr_pay.saudi_services.flash' }),
    logo: <FlashIcon className="br-payment__flash-logo" />
  }
};

export const MOYASAR_STATUS = {
  SEMI_ACTIVE: 'semi_active',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  REVIEW_FAILED: 'review_failed',
  HOLD: 'hold',
  UN_HOLD: 'un_hold',
  DISABLED: 'disabled'
};

export const MOYASAR_REJECTED_STATUS = [
  MOYASAR_STATUS.REJECTED,
  MOYASAR_STATUS.HOLD
];

export const MOYASAR_NOT_ACTIVATED_STATUS = [
  MOYASAR_STATUS.SEMI_ACTIVE,
  MOYASAR_STATUS.REVIEW_FAILED,
  ...MOYASAR_REJECTED_STATUS
];
