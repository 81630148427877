import { PhoneNumberUtil } from 'google-libphonenumber';

import {
  AT_LEAST_ONE_DIGIT,
  AT_LEAST_EIGHT_LETTERS,
  STORE_LINK_REGEX,
  FIXED_LINE,
  INCLUDES_DIGITS_REGEX
} from 'constants/Forms';
import { isSaudi } from 'constants/helpers';
import {
  getCurrentUserCountryData,
  getIbanRegex,
  getIdentityNumberRegex,
  getPhoneNumberRegex
} from 'constants/countries/countries-mapping';

const emailValidator = (value, message) => {
  const emailExp =
    /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]+@[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]+\.[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]+$/;

  if (!value || emailExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const emailRule = (message) => ({
  validator: (_, value) => emailValidator(value, message)
});

export const requiredRule = (message) => ({
  required: true,
  message
});

export const whiteSpaceRule = (message) => ({
  whitespace: true,
  message
});

export const notRequiredRule = () => ({
  required: false
});

const phoneNumberValidator = ({
  value,
  message,
  internationlNumbers,
  landlineNumbers,
  country,
  withoutCountryCode
}) => {
  const phoneRegex = getPhoneNumberRegex({
    countryCode: country.codeName,
    withoutCountryCode
  });
  const phoneUtil = PhoneNumberUtil.getInstance();
  let number;

  if (!value || phoneRegex.test(value)) {
    return Promise.resolve();
  }

  try {
    number = phoneUtil.parseAndKeepRawInput(value, country.codeName);
  } catch (e) {
    if (!value) {
      return Promise.resolve();
    }
    return Promise.reject(message);
  }

  if (
    internationlNumbers &&
    phoneUtil.isValidNumber(number) &&
    phoneUtil.getNumberType(number) !== FIXED_LINE
  ) {
    return Promise.resolve();
  } else if (landlineNumbers && phoneUtil.isValidNumber(number)) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const phoneNumberRule = ({
  message,
  internationlNumbers,
  landlineNumbers,
  country = getCurrentUserCountryData(),
  withoutCountryCode
}) => ({
  validator: (_, value) =>
    phoneNumberValidator({
      value,
      message,
      internationlNumbers,
      landlineNumbers,
      country,
      withoutCountryCode
    })
});

const numberValidator = (value, message) => {
  const numberRegExp = /^\d+$/;
  if (!value || numberRegExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const numberRule = (message) => ({
  validator: (_, value) => numberValidator(value, message)
});

const lengthRuleValidator = (value, message, length) => {
  if (value && value.length <= length) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const lengthRule = (message, length) => ({
  validator: (_, value) => lengthRuleValidator(value, message, length)
});

const samePasswordValidator = (value, message, passwordValue) => {
  if (!value || passwordValue === value) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const samePassword = (message, passwordValue) => ({
  validator: (_, value) => samePasswordValidator(value, message, passwordValue)
});

export const validateInputPhoneNumberOnly = (event) => {
  const _event = event || window.event;
  let key = _event.keyCode || _event.which;
  key = String.fromCharCode(key);
  const regex = /^\+?(?:[\d]*)$/;
  if (!regex.test(key)) {
    _event.returnValue = false;
    if (_event.preventDefault) _event.preventDefault();
  }
};

export const passwordValidator = (value, message) => {
  //at least 8 characters and one digit.
  if (
    !value ||
    (AT_LEAST_ONE_DIGIT.test(value) && AT_LEAST_EIGHT_LETTERS.test(value))
  ) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const passwordRule = (message) => ({
  validator: (_, value) => passwordValidator(value, message)
});

const fullNameValidator = (value, message) => {
  const fullNameRegex = /\s/;

  if (!value || fullNameRegex.test(value.trim())) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const fullNameRule = (message) => ({
  validator: (_, value) => fullNameValidator(value, message)
});

const accountOwnerNameValidator = (value, message) => {
  const accountOwnerNameRegex = /^[\D]{0,255}$/;

  if (!value || accountOwnerNameRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const accountOwnerNameRule = (message) => ({
  validator: (_, value) => accountOwnerNameValidator(value, message)
});

const ibanValidator = (value, message) => {
  const ibanRegex = getIbanRegex();

  if (!value || ibanRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const ibanRule = (message) => ({
  validator: (_, value) => ibanValidator(value, message)
});

const conatinUrl = (value, message) => {
  const name =
    /((http:\/\/|https:\/\/)?(www.)?(([a-zA-Z0-9-]){1,}\.){1,4}([a-zA-Z]){1,6}(\/([a-zA-Z-_0-9#:?=&;,]*)?)?)/;
  if (!value || !name.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const conatinUrlRule = (message) => ({
  validator: (_, value) => conatinUrl(value, message)
});

const codValidator = (value, maxMessage, minMessage, maxValue, minValue) => {
  if (
    (value && value <= maxValue && value >= minValue) ||
    !value ||
    !Number.isInteger(parseInt(value))
  ) {
    return Promise.resolve();
  }
  if (value <= 1) {
    return Promise.reject(minMessage);
  }
  return Promise.reject(maxMessage);
};

export const codRule = (
  maxMessage,
  minMessage,
  maxValue = 30000,
  minValue = 1
) => ({
  validator: (_, value) =>
    codValidator(value, maxMessage, minMessage, maxValue, minValue)
});

export const storeLinkValidator = (value, message) => {
  if (!value || STORE_LINK_REGEX.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const storeLinkRule = (message) => ({
  validator: (_, value) => storeLinkValidator(value, message)
});

const itemsCountValidator = (value, minMessage) => {
  value = parseInt(value || 0);
  if (value < 1) {
    return Promise.reject(minMessage);
  }
  return Promise.resolve();
};

export const itemsCountRule = (minMessage) => ({
  validator: (_, value) => itemsCountValidator(value, minMessage)
});

const customerNameValidator = (value, message) => {
  if (!value) {
    return Promise.resolve();
  } else if (INCLUDES_DIGITS_REGEX.test(value)) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const customerNameRule = (message) => ({
  validator: (_, value) => customerNameValidator(value, message)
});

const englishNameRuleValidator = (value, message) => {
  const englishNameRegex = /^([a-zA-Z ])+$/;

  if (!value || englishNameRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const englishNameRule = (message) => ({
  validator: (_, value) => englishNameRuleValidator(value, message)
});

export const itemQuantityValidator = (value, message) => {
  const intValue = parseInt(value);
  const hasWhiteSpace = (text) => {
    return text.toString().indexOf(' ') >= 0;
  };

  if (intValue < 1 || !intValue || hasWhiteSpace(value)) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const itemQuantityRule = (message = '') => ({
  validator: (_, value) => itemQuantityValidator(value, message)
});

export const priceValidator = (defaultValue, orignalValue, message) => {
  if (parseFloat(defaultValue) >= parseFloat(orignalValue)) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const pricesRule = (defaultValue, orignalValue, message) => ({
  validator: () => priceValidator(defaultValue, orignalValue, message)
});

export const valueDifferenceValidator = (valueOne, valueTwo, message) => {
  if (parseFloat(valueOne) >= parseFloat(valueTwo)) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const valueDifferenceRule = (valueOne, valueTwo, message) => ({
  validator: () => valueDifferenceValidator(valueOne, valueTwo, message)
});

export const moyasarValidator = (moyasarErrors, fieldName) => {
  if (moyasarErrors[fieldName]) {
    return Promise.reject(moyasarErrors[fieldName][0]);
  }

  return Promise.resolve();
};

export const moyasarErrorsRule = (moyasarErrors, fieldName) => ({
  validator: (_, value) => moyasarValidator(moyasarErrors, fieldName)
});

export const percentageValidator = (value, message) => {
  if (parseInt(value) === 0 || parseInt(value) > 100) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const percentageRule = (message = '') => ({
  validator: (_, value) => percentageValidator(value, message)
});

const swiftNumberValidator = (value, message) => {
  const swiftRegex = /^([a-zA-Z]{1,11})+$/;

  if (!value || swiftRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const switfNumberRule = (message) => ({
  validator: (_, value) => swiftNumberValidator(value, message)
});

const accountNumberValidator = (value, message) => {
  const accountNumberRegex = isSaudi() ? /^[0-9]{1,22}$/ : /^[0-9]{1,19}$/;

  if (!value || accountNumberRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const accountNumberRule = (message) => ({
  validator: (_, value) => accountNumberValidator(value, message)
});

const nationalIdValidator = (value, message) => {
  const nationalIdRegex = isSaudi() ? /^[0-9]{10}$/ : /^[0-9]{14}$/;

  if (!value || nationalIdRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const nationalIdRule = (message) => ({
  validator: (_, value) => nationalIdValidator(value, message)
});

const taxIdValidator = (value, message) => {
  const taxIdRegex = /^[0-9]{9}$/;

  if (!value || taxIdRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const taxIdRule = (message) => ({
  validator: (_, value) => taxIdValidator(value, message)
});

const businessNameValidator = (value, message) => {
  const businessNameRegex = /^([a-zA-Z0-9٠-٩ا-ي ])+$/;

  if (!value || (value && businessNameRegex.test(value))) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const businessNameRule = (message) => ({
  validator: (_, value) => businessNameValidator(value, message)
});

export const donationAmountRule = (msg) => ({
  validator: (_, value) => {
    if ((value && value < 0.5) || value === 0 || (value && value > 99.9)) {
      return Promise.reject(msg);
    }

    return Promise.resolve();
  }
});

const identityNumberValidator = (value, message) => {
  const identityRegex = getIdentityNumberRegex();

  if (!value || (value && identityRegex.test(value))) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const rangeValidator = ({ message, min, max, value }) => {
  if (value >= min && value <= max) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const identityNumberRule = (message) => ({
  validator: (_, value) => identityNumberValidator(value, message)
});

export const rangeRule = ({ message, min, max }) => ({
  validator: (_, value) => rangeValidator({ message, min, max, value })
});

const minNumberValidator = ({ value, message, min }) => {
  if (!value || Number(value) >= min) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const minNumberRule = ({ message, min }) => ({
  validator: (_, value) => minNumberValidator({ value, message, min })
});

const maxNumberValidator = ({ value, message, max }) => {
  if (!value || Number(value) < max) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const maxNumberRule = ({ message, max }) => ({
  validator: (_, value) => maxNumberValidator({ value, message, max })
});

const requiredNumberValidator = ({ value, message }) => {
  if (value) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const requiredValueRule = ({ message }) => ({
  validator: (_, value) => requiredNumberValidator({ value, message })
});

export const pickupLocationsAreaRequiredValidator = ({
  value,
  message,
  pickupLocations
}) => {
  const pickupLocation = pickupLocations.find(({ _id }) => _id === value);

  if (!pickupLocation?.address?.district) {
    return Promise.reject(message);
  }

  return Promise.resolve();
};

export const pickupLocationsAreaRequired = ({ message, pickupLocations }) => ({
  validator: (_, value) =>
    pickupLocationsAreaRequiredValidator({ value, message, pickupLocations })
});
