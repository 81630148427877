import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Modal } from 'antd';

import { setInsurancePlan } from 'services/signup';
import { updateInsurancePlan } from 'services/insurance';

import InsurancePlanDetails from './components/InsurancePlanDetails/InsurancePlanDetails';
import InsurancePlanConfirmation from './components/InsurancePlanConfirmation/InsurancePlanConfirmation';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './InsurancePlanModal.less';

const InsurancePlanModal = ({
  intl,
  history,
  plan,
  isSettings,
  handleSignupSuccess,
  handleClosePopupModal,
  getPlans,
  planDetails,
  close,
  centered,
  ...props
}) => {
  const { isDefault, rank, _id } = plan;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const isSignup = history.location.pathname.includes('signup');

  const handleConfirmPlan = async () => {
    setIsLoading(true);
    try {
      const payload = {
        insurancePlanId: _id
      };

      if (isSignup) {
        await setInsurancePlan(payload);
      } else {
        await updateInsurancePlan(payload);
      }

      setIsSubscribed(true);
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      userInfo.user = {
        ...userInfo.user,
        insuranceSelected: _id
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    if (isSettings && isSubscribed) {
      getPlans();
    }

    if (handleClosePopupModal) {
      handleClosePopupModal();
    }

    close();
  };

  return (
    <Modal
      className="br-insurance-modal-container"
      title={null}
      footer={null}
      width={null}
      centered={true}
      maskClosable={false}
      wrapClassName={'br-insurance-modal'}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <div className="br-insurance-modal__content">
          {isSubscribed ? (
            <InsurancePlanConfirmation
              isDefault={isDefault}
              rank={rank}
              handleSignupSuccess={handleSignupSuccess}
              close={handleCloseModal}
            />
          ) : (
            <InsurancePlanDetails
              plan={plan}
              handleConfirmPlan={handleConfirmPlan}
              handleCloseModal={handleCloseModal}
              planDetails={planDetails}
            />
          )}
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default withRouter(injectIntl(InsurancePlanModal));
