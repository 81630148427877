import React, { createContext, useCallback, useContext, useState } from 'react';
import { Router, BrowserRouter } from 'react-router-dom';

import { ContextWrapper } from './wrapper.context';
import { generateUUID } from 'utils/helpers';

export const ModalContext = createContext(null);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const businessInfoData = useContext(ContextWrapper);
  const [modals, setModals] = useState([]);

  const closeModal = useCallback((modalId) => {
    setModals((prevModals) =>
      prevModals.filter((modal) => {
        const { modalId: id } = modal().props.children.props;
        return modalId !== id;
      })
    );
  }, []);

  const openModal = useCallback((Component, componentProps) => {
    const modalId = generateUUID();
    const ModalComponent = () =>
      componentProps?.history ? (
        <Router history={componentProps.history}>
          <Component
            {...componentProps}
            visible={true}
            centered={true}
            close={() => closeModal(modalId)}
            modalId={modalId}
          />
        </Router>
      ) : (
        <BrowserRouter>
          <Component
            {...componentProps}
            visible={true}
            centered={true}
            close={() => closeModal(modalId)}
            modalId={modalId}
          />
        </BrowserRouter>
      );
    setModals((prevModals) => [...prevModals, ModalComponent]);
  }, [closeModal]);

  return (
    <ModalContext.Provider
      value={{ ...businessInfoData, openModal, closeModal }}
    >
      {children}
      {modals.map((ModalComponent, index) => (
        <ModalComponent key={index} />
      ))}
    </ModalContext.Provider>
  );
};
