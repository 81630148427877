import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useModal } from './modalProvider.context';

const AnnouncementModalsContext = createContext({
  registerAnnouncement: () => {},
  unregisterAnnouncement: () => {}
});

export const useAnnouncementModals = (announcementComponent = undefined) => {
  const { registerAnnouncement, unregisterAnnouncement } = useContext(
    AnnouncementModalsContext
  );

  useEffect(() => {
    if (!announcementComponent) {
      return;
    }

    if (Array.isArray(announcementComponent)) {
      announcementComponent.forEach(registerAnnouncement);
      return () => announcementComponent.forEach(unregisterAnnouncement);
    }

    registerAnnouncement(announcementComponent);
    return () => unregisterAnnouncement(announcementComponent);
  }, [announcementComponent, registerAnnouncement, unregisterAnnouncement]);
};

export const AnnouncementModalsProvider = memo(({ children }) => {
  const { openModal } = useModal();
  const [announcementModals, setAnnouncementModals] = useState([]);

  const firstAnnouncement = useMemo(
    () => announcementModals[0],
    [announcementModals]
  );

  const registerAnnouncement = useCallback((announcementComponent) => {
    if (!announcementComponent.showModal) {
      return;
    }

    setAnnouncementModals((curr) => {
      const updatedBanners = [...curr, announcementComponent];
      return updatedBanners
        .sort((a, b) => a.priority - b.priority)
        .filter((b) => b.showModal);
    });
  }, []);

  const unregisterAnnouncement = useCallback((announcementComponent) => {
    setAnnouncementModals((curr) =>
      curr.filter(
        (item) => item.id !== announcementComponent.id && item.showModal
      )
    );
  }, []);

  useEffect(() => {
    if (!firstAnnouncement?.component) {
      return;
    }
    openModal(
      firstAnnouncement?.component,
      firstAnnouncement?.componentProps ?? {}
    );
  }, [firstAnnouncement, openModal]);

  return (
    <AnnouncementModalsContext.Provider
      value={{
        registerAnnouncement,
        unregisterAnnouncement
      }}
    >
      {children}
    </AnnouncementModalsContext.Provider>
  );
});
