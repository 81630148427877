import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as DefaultPlanIcon } from 'assets/bosta-icons/insurance-default-plan-icon.svg';
import { ReactComponent as PremiumPlanIcon } from 'assets/bosta-icons/insurance-premium-plan-icon.svg';

import './InsurancePlanDetails.less';

const InsurancePlanDetails = ({
  intl,
  plan,
  close,
  handleCloseModal,
  handleConfirmPlan,
  planDetails,
  ...props
}) => {
  const { rank, isDefault } = plan;

  const planTitle = intl.formatMessage({
    id: `insurance.plans.rank_${rank}.title`
  });

  const planDetailsBullets = () =>
    isDefault ? (
      <ul className="br-insurance-details-modal__default-plan-bullets">
        {planDetails?.map((feature, index) => (
          <li
            key={index}
            className="br-insurance-details-modal__info-features-default-plan"
          >
            <span className="br-insurance-details-modal__info-single-feature">
              {feature}
            </span>
          </li>
        ))}
      </ul>
    ) : (
      <ul className="br-insurance-details-modal__premium-plan-bullets">
        {planDetails?.map((feature, index) => (
          <li
            key={index}
            className="br-insurance-details-modal__info-features-premium-plan"
          >
            <CircleCheck />

            <span className="br-insurance-details-modal__info-single-feature">
              {feature}
            </span>
          </li>
        ))}
      </ul>
    );
  return (
    <div className="br-insurance-details-modal__container">
      <div className="br-insurance-details-modal__header">
        {isDefault ? (
          <>
            <DefaultPlanIcon />
            <span className="br-insurance-details-modal__info-title">
              {intl.formatMessage({
                id: `insurance.modal.default_headline`
              })}
            </span>
          </>
        ) : (
          <>
            <PremiumPlanIcon />
            <span className="br-insurance-details-modal__info-title">
              {intl.formatMessage(
                {
                  id: `insurance.modal.premium_headline`
                },
                {
                  title: planTitle
                }
              )}
            </span>
          </>
        )}
      </div>

      <div
        className={classNames('br-insurance-details-modal__content', {
          'br-insurance-details-modal__content-default-plan': isDefault
        })}
      >
        <span className="body-medium">
          {intl.formatMessage({
            id: `insurance.modal.agree_on_the_following`
          })}
        </span>
        {planDetailsBullets()}
      </div>

      {isDefault ? (
        <span className="br-insurance-details-modal__warning">
          {intl.formatMessage({
            id: `insurance.modal.default_confirm_message`
          })}
        </span>
      ) : (
        <div className="br-insurance-details-modal__warning">
          <span>
            {intl.formatMessage({
              id: `insurance.modal.warning_message`
            })}
          </span>
          <span>
            {intl.formatMessage({
              id: `insurance.modal.confirm_message`
            })}
          </span>
        </div>
      )}

      <div className="br-insurance-details-modal__actions">
        <BRButton
          className="br-insurance-details-card__premium-plan-btn"
          type={'basic'}
          label={intl.formatMessage({
            id: `insurance.modal.back_action`
          })}
          onClick={handleCloseModal}
        />

        <BRButton
          className="br-insurance-details-card__premium-plan-btn"
          type={'primary'}
          label={intl.formatMessage({
            id: `insurance.modal.confirm_action`
          })}
          onClick={handleConfirmPlan}
        />
      </div>
    </div>
  );
};

export default injectIntl(InsurancePlanDetails);
